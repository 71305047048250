import React, { useState, useEffect } from "react";
import { Drawer, Card, Badge, Button, Tooltip, Space } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { LongText, CopyText } from "../../../components";
import { parseFullDate } from "../../../config/formats";
import { ICONS } from "../../../config";
import REQUESTS from "../../../api/requests";
import useTranslate from "../../../hooks/translator";

export default function CurrentDevice({ open, onCancel, current }) {
  const translate = useTranslate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    REQUESTS.DEVICE_INFO({ id: current?.id })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {});
  };

  const checkPlaylistForError = (playlist_status) => {
    switch (playlist_status) {
      case "wrong":
        return translate(
          "Please check the playlist as it is incorrectly filled in."
        );

      case "expired":
        return translate(
          "Please check this playlist as its expiration date has expired."
        );
      default:
        return "";
    }
  };
  return (
    <Drawer
      forceRender
      open={open}
      onClose={onCancel}
      placement="right"
      title={translate("Device info")}
      width={1000}
    >
      <div className="about-device">
        <div className="part-1">
          <div>
            <Badge.Ribbon
              text={
                current
                  ? current?.is_active
                    ? translate("Active")
                    : !current?.is_active && current?.is_trial
                    ? translate("Trial")
                    : current?.black_list
                    ? translate("Blocked")
                    : translate("Deactive")
                  : translate("Device not found")
              }
              color={
                current
                  ? current?.is_active
                    ? "green"
                    : !current?.is_active && current?.is_trial
                    ? "gold"
                    : "red"
                  : "grey"
              }
            >
              <Card
                title={translate("Device")}
                loading={loading}
                className="card"
              >
                {current && (
                  <ul className="card-info">
                    <li>
                      <span>{translate("Device key")}</span>
                      {current?.device_key ? (
                        <CopyText text={current?.device_key} />
                      ) : (
                        "N/A"
                      )}
                    </li>
                    <li>
                      <span>{translate("Platform")}</span>
                      <strong>{current?.device_os || "N/A"}</strong>
                    </li>
                    <li>
                      <span>{translate("Device UUID")}</span>
                      <strong>
                        {<LongText children={current?.device_uuid} /> || "N/A"}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("App name")}</span>
                      <strong>{current?.app_name || "N/A"}</strong>
                    </li>
                    <li>
                      <span>{translate("Activated by")}</span>
                      <strong>{current?.activated_by || "N/A"}</strong>
                    </li>
                    <li>
                      <span>{translate("Blocked")}</span>
                      <strong>
                        {current?.black_list ? ICONS.TRUE : ICONS.FALSE}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("Is active")}</span>
                      <strong>
                        {current?.is_active ? ICONS.TRUE : ICONS.FALSE}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("Is online")}</span>
                      <strong>
                        {current?.is_online ? ICONS.TRUE : ICONS.FALSE}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("Created date")}</span>
                      <strong>
                        {parseFullDate(current?.createdAt) || "N/A"}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("Updated date")}</span>
                      <strong>
                        {parseFullDate(current?.updatedAt) || "N/A"}
                      </strong>
                    </li>
                    <li>
                      <span>{translate("App version")}</span>
                      <strong>{current?.app_version || "N/A"}</strong>
                    </li>
                    <li>
                      <span>{translate("Model")}</span>
                      <strong>{current?.model || "N/A"}</strong>
                    </li>
                    <li>
                      <span>{translate("Browser version")}</span>
                      <strong>{current?.browser_version || "N/A"}</strong>
                    </li>
                  </ul>
                )}
              </Card>
            </Badge.Ribbon>
          </div>
          {data?.black_list && (
            <div>
              <Card title={translate("Blacklist")} loading={loading}>
                <ul className="card-info">
                  <li>
                    <span>{translate("Device key")}</span>
                    {current?.device_key ? (
                      <CopyText text={current?.device_key} />
                    ) : (
                      "N/A"
                    )}
                  </li>

                  <li>
                    <span>{translate("Platform")}</span>
                    <strong>{data?.black_list?.device_os || "N/A"}</strong>
                  </li>

                  <li>
                    <span>{translate("Device UUID")}</span>
                    <strong> {data?.black_list?.device_uuid || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Created date")}</span>
                    <strong>
                      {parseFullDate(data?.black_list?.createdAt) || "N/A"}
                    </strong>
                  </li>
                  <li>
                    <span>{translate(translate("Updated date"))}</span>
                    <strong>
                      {parseFullDate(data?.black_list?.updatedAt) || "N/A"}
                    </strong>
                  </li>
                </ul>
              </Card>
            </div>
          )}
        </div>

        <div className="part-2">
          <div>
            <Card title={translate("Playlist")} loading={loading}>
              {current && (
                <ul className="card-info">
                  <li>
                    <span>{translate("IP address")}</span>
                    {current.ip_address ? (
                      <CopyText text={current.ip_address} />
                    ) : (
                      "N/A"
                    )}
                  </li>

                  <li>
                    <span>{translate("Is trial")}</span>
                    <strong>
                      {current.is_trial ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("Is lifetime")}</span>
                    <strong>
                      {current.is_lifetime ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("Auto renew")}</span>
                    <strong>
                      {current.auto_renew ? ICONS.TRUE : ICONS.FALSE}
                    </strong>
                  </li>
                  <li>
                    <span>{translate("Expired date")}</span>
                    <strong>
                      {parseFullDate(current.expired_date) || "N/A"}
                    </strong>
                  </li>

                  <li>
                    <span>{translate("Free trial expired")}</span>
                    <strong>{parseFullDate(current.free_trial_expired)}</strong>
                  </li>
                  <li>
                    <span>{translate("Playlist")}</span>
                    {current.playlist ? (
                      <div>
                        {(() => {
                          const playlistStatus =
                            current.playlist_status?.toLowerCase();

                          if (
                            playlistStatus === "wrong" ||
                            playlistStatus === "expired"
                          ) {
                            const { errorMessage } =
                              checkPlaylistForError(playlistStatus);
                            return (
                              <>
                                <Tooltip title={errorMessage}>
                                  <Button
                                    icon={<InfoCircleOutlined />}
                                    type="link"
                                    className="card-info-icon"
                                    style={{
                                      color: "#cf1222",
                                    }}
                                  />
                                </Tooltip>
                                <Space>
                                  <CopyText
                                    text={
                                      <LongText
                                        children={current.playlist}
                                        style={{
                                          color: "#cf1222",
                                        }}
                                      />
                                    }
                                    style={{ color: "#cf1222" }}
                                  />
                                </Space>
                              </>
                            );
                          }

                          return (
                            <CopyText
                              text={<LongText children={current.playlist} />}
                            />
                          );
                        })()}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </li>
                </ul>
              )}
            </Card>
          </div>

          {data?.server && (
            <div>
              <Card title={translate("Server info")} loading={loading}>
                <ul className="card-info">
                  <li>
                    <span>{translate("Name")}</span>
                    <strong> {data?.server?.name || "N/A"}</strong>
                  </li>
                  <li>
                    <span>{translate("Host")}</span>
                    <strong> {data?.server?.host || "N/A"}</strong>
                  </li>
                </ul>
              </Card>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
}
